.search-unit-v1 {

	.unit-row {
		padding: 15px;
		background-color: #fff;
		position: relative;
	}

	.img-col {
		position: relative;
		overflow: hidden;
		margin-left: 15px;

		@media (max-width: 1200px) {
			margin-right: 15px;
		}

		img {
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			height: 100%;
			width: 100%;
			min-height: 200px;
		}
	}

	.info-col {
		@media (max-width: 1200px) {
			padding-top: 15px;
		}

		.h3 {
			color: var(--color-primary);
		}

		.unit-place {
			font-size: var(--font-size-md);
		}
	}

	.plus-object {
		margin-top: 5px;
	}


	.tick-li {

		li {
			font-size: var(--font-size-md);
			padding-left: 20px;
			padding-right: 20px;
			position: relative;
			display: inline-block;

			&:before {
				content: "\f00c";
				font-family: "Font Awesome 5 Free";
				-webkit-font-smoothing: antialiased;
				font-weight: 600;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0;
				color: var(--color-primary);
			}
		}

	}

	.fav.col {
		position: relative;
	}

	.fav-icon {
		position: absolute;
		right: 10px;
		top: -5px;
		color: #fff;
		cursor: pointer;
		height: 30px;
		width: 25px;
		background-color: var(--color-secondary);
		text-align: center;
		z-index: 5;
		line-height: 30px;

		@media(max-width:1199px) {
			right: 26px;
		}
	}

	.region {
		position: absolute;
		left: 0px;
		top: 0px;
		color: #fff;
		background-color: var(--color-primary);
		padding-left: 10px;
		padding-right: 30px;
		padding-top: 2px;
		padding-bottom: 2px;
		min-width: 150px;
		font-size: var(--font-size-md);
		border-bottom-right-radius: 36px;


	}

	.properties-row {

		display: flex;
		margin-top: 10px;

		@media(max-width:1200px) {
			margin-bottom: 20px;
		}



		.unit-properties {
			width: 50%;
			font-size: var(--font-size-md);

			div {
				position: relative;
				padding-left: 20px;

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 2px;
					color: var(--color-secondary);
				}
			}
		}
	}

	.unit-specials {
		margin-bottom: 10px;
		margin-top: 10px;

		div {
			display: inline-block;
			font-size: var(--font-size-md);
			padding: 2px 8px;
			background-color: var(--color-grey-normal);
			border-radius: 5px;
		}
	}

	.unit-highlights {
		position: absolute;
		text-align: right;

		@media(max-width:1199px) {
			bottom: -28px;
			left: 10px;

		}

		@media(min-width:1200px) {
			bottom: 10px;
			left: 10px;

		}

		.fa {
			padding-left: 5px;


		}

		.fa-stack {
			height: 33px !important;
			margin-left: -5px;
			width: 24px !important;
		}
	}

	.unit-price-col {
		display: table;

		.unit-act-row {
			display: table-cell;
			vertical-align: bottom;
		}
	}

	.rent {
		text-align: center;
	}

	.small {
		font-size: var(--font-size-md);

		@media (min-width: 1201px) {
			&:after {
				content: "\A";
			}
		}
	}

	.main-price {
		font-weight: 600;
		text-decoration: line-through;
	}

	.discount-price {
		font-weight: 600;

		.reducedprice {
			color: var(--color-red);
		}
	}

	.prices {
		text-align: center;

		@media (max-width: 1200px) {
			text-align: right;
			min-height: 25px;
		}

	}

	.offer-box {
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
		display: flex;
		margin: 0;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		color: var(--color-black);
		border-top: 1px dashed var(--color-grey-normal);
		border-bottom: 1px dashed var(--color-grey-normal);
		margin-top: 15px;

		@media (max-width:1200px) {
			display: block
		}

		.offer-left {
			background: var(--color-secondary);
			color: var(--color-white);
			padding: 10px 15px;
			border: 1px dashed var(--color-primary);
			font-size: 16px;
			text-transform: uppercase;
			display: flex;
			align-items: center;
			margin-right: 15px;

			@media (max-width:1200px) {
				display: block;
				text-align: center;
				margin-right: 0;
				margin-bottom: 15px
			}
		}

		ul {
			padding-top: 8px;
			list-style: none;
			margin: 0;

			li {
				margin: 0 0 5px;
				line-height: 14px;
				font-size: 14px
			}
		}

		a:hover {
			text-decoration: none;
		}
	}

	.alternatives {
		margin-top: 15px;
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 1px;
		background-color: var(--color-grey-light);

		.altheading {
			font-weight: 600;
			color: var(--color-red);

		}



		a {
			color: var(--font-color-main);
			text-decoration: underline !important;

			&:hover {
				text-decoration: none !important;
				color: var(--color-red);
			}


		}

		.fa {
			color: var(--color-red);
			font-size: 12px;
			line-height: 16px;
		}

		.invalid {
			text-decoration: line-through;
		}

		.special {
			color: var(--color-red);
			font-weight: 600;
		}

	}



}

.popover {
	border-radius: 0;
	background-color: var(--color-white);
	border: 1px solid #C0C0C0;
	font-size: var(--font-size-md);
	box-shadow: inherit;
	width: 100%;
	max-width: 520px;

	.popover-header {
		border-radius: 0;
		background-color: var(--color-grey-light);
		margin-bottom: 10px;
		padding: 10px 10px;
		font-size: var(--font-size-md);
		font-weight: normal;
	}

	.arrow {
		&:after {
			border-top-color: #C0C0C0 !important;
		}
	}

	.popover-body {
		padding: 10px;


		ul {
			overflow-y: scroll;
			height: 180px;
			padding-right: 10px;


			li {
				position: relative;
				margin-bottom: 2px;
				padding-left: 14px;
				list-style: none;
				font-size: var(--font-size-md);
				font-family: var(--font-family-main);
				margin-bottom: 3px;

				&:before {
					background-color: var(--color-primary);
					width: 5px;
					height: 5px;
					position: absolute;
					content: "";
					left: 0;
					top: 6px;
					border-radius: 50%;

				}
			}
		}
	}
}