.erlebnistipps {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-villa-luna.jpg);
	}

	.image {
		margin-bottom: 20px;
		margin-top: 30px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}