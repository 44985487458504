.allgemeine-buchungsanfrage {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-winter.jpg);
	}

	label {
		display: none;
	}

}