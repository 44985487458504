.unit-title-v1 {
	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}

	h1 {
		font-size: 1.6rem;
	}

	.plus-object {
		margin-top: 6px;
	}
}