.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 2px solid var(--footer-border-top-color);

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}


		.logo {
			width: 190px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
			margin-left: -15px;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);

			&:hover {
				color: var(--color-primary);
				text-decoration: none;
			}


		}

		.fas {
			color: var(--color-primary);
			padding-right: 5px;
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}



	.bottom-footer {

		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		padding-left: 5vw;
		padding-right: 5vw;

		.links {
			@media (max-width:768px) {
				text-align: center;
			}

			a {
				display: inline-block;
				color: var(--color-white);
				text-decoration: none;
				padding-right: 20px;
				font-size: 14px;

				@media (max-width:768px) {
					padding-right: 10px;
					padding-left: 10px;
				}

				@media (max-width:556px) {
					display: block;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}

		.copyright {
			font-size: 14px;
			text-align: right;

			@media (max-width:768px) {
				text-align: center;
				padding-top: 20px;
			}
		}

	}
}