.kontakt {

	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-brocken.jpg);
	}

	.image-desk {
		padding-top: 30px;
		float: right;

		@media (max-width: 1280px) {
			width: 60%;
		}

	}

	.teamfoto {
		objekt-fit: cover;
		height: 100%;
		width: 100%;

	}

}