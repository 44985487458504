.unit-booking-v2 {

	h2 {
		font-size: 1.1rem;
		font-weight: 400;
		font-weight: var(--h4-font-weight);
		color: var(--color-grey-dark);
		margin-bottom: 1rem;
	}

	.search-bl {
		position: relative;

		.fa {
			position: absolute;
			right: 10px;
			top: 9px;
			color: var(--color-grey-dark);
		}
	}

	.form-control {
		border: none;
		border: 1px solid #e6e6e6;
		text-align: left;
		margin-bottom: 15px;
		padding: 10px 5px;
		background-color: var(--color-white);
		font-size: var(--font-size-md);
	}

	.table {
		td {
			border-top: 2px solid var(--color-white);
		}

		tbody {
			border-top: 2px solid var(--color-white);
		}
	}

	.optionals {
		margin-top: 20px;
	}

	.optionals-title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		padding-left: 10px;
	}

	.consumptioncosts-title {
		font-size: var(--h5-font-size);
		font-weight: var(--h5-font-weight);
		padding-left: 10px;
	}

	.button-row {
		text-align: right;
		margin-top: 20px;

	}

	.subrow {
		margin-top: 20px;
	}
}