.unit-page-layout-v3 {
	margin-top: var(--page-margin-top);

	.left-col {
		padding: 0px 15px;

		@media(max-width: 992px) {
			width: 100% !important;
		}

		@media(min-width: 993px) {
			width: 66% !important;


		}

		@media(min-width: 1200px) {
			width: 67% !important;
		}

		@media(min-width: 1500px) {
			width: 67% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 992px) {
			display: none;
		}

		@media(min-width: 993px) {
			width: 34% !important;

		}

		@media(min-width: 1200px) {
			width: 33% !important;
		}

		@media(min-width: 1500px) {
			width: 33% !important;
		}
	}

	.spacer {
		width: 100%;
		height: 1px;
		border-bottom: 1px solid var(--color-grey-normal);
	}

	.card {
		background-color: transparent;
		border-top: 1px solid var(--color-grey-normal);
		border-bottom: none;
		border-right: none;
		border-left: none;

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 7px 0px;

			h5 {
				span {
					font-size: 18px;
					font-weight: normal;
					text-transform: uppercase;

					&:hover {
						color: var(--color-secondary);
					}

					&.active {
						color: var(--color-primary);
						font-weight: 600;
					}
				}
			}

		}

		.card-body {
			padding: 0px;
			padding-bottom: 10px;
			padding-top: 5px;
		}

		.fas {
			color: #bab8b8;
			position: absolute;
			right: 0px;
			font-size: 20px;
			top: 20px;
		}

	}

	.cal-title {

		border-top: 1px solid var(--color-grey-normal);
		padding: 20px 0px;

		h5 {
			font-size: 18px;
			font-weight: 400;
			text-transform: uppercase;
		}
	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);
		margin-left: -14px;

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

}