.unit-highlights-v3 {

	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.highlight {
		margin-right: 30px;
	}

}