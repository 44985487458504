.unser-team {

	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-brocken.jpg);
	}

	.member {
		margin-bottom: 15px;
		clear: left;

		.image {
			padding-right: 15px;

			@media (min-width:768px) {
				float: left;
			}

		}
	}
}