.unit-offers-v1 {
	.unit-offer {
		background-color: var(--color-white);
		border: 1px solid var(--color-grey-normal);
		position: relative;
		display: flex;
		padding: 5px;
		margin-bottom: 10px;


		h4 {
			font-weight: 400;
		}

		.offer-pic {
			font-size: 36px;
			font-weight: bold;
			text-align: center;
			color: var(--color-primary);
			width: 60px;
		}

		.offer-minstay {
			font-size: var(--font-size-md);

		}

		.offer-minstay-xy {
			font-size: var(--font-size-md);

		}

		.offer-condition {
			font-size: var(--font-size-md);
		}

		/*.offer-banner {
			position: absolute;
			background-color: var(--color-red);
			color: var(--color-white);
			top: -13px;
			left: -8px;
			padding: 2px 10px;
			text-transform: uppercase;
			font-size: var(--font-size-sm);
			border-radius: 5px;
			box-shadow: 1px 0 3px 0 rgb(0 0 0 / 35%);

		} */


	}
}