.booking-v1 {

	.booking-view {
		background-color: var(--booking-view-background-color);

		padding-top: var(--page-margin-top);

	}

	.required::after {
		content: "*";
		vertical-align: super;
		font-size: var(--font-size-sm);
	}

	.label {
		display: inline-block;
	}

	.cart-details {
		padding: 14px;
		background-color: var(--color-white);

		.unit-details {
			margin: 20px 0;
			color: var(--color-grey-dark);

			li {
				padding-left: 30px;
				padding-right: 15px;
				margin-top: 5px;
				position: relative;

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--color-primary);
				}
			}
		}
	}

	.pd-form {
		label {
			font-weight: 500;
		}

	}

	.checks {

		.checkbox {

			label {
				vertical-align: inherit;
			}
		}
	}


	.flow-btn {
		margin-left: 20px;
	}

	.progressbar {
		width: 100%;
		position: relative;
		height: 60px;

		.label {
			padding-bottom: 20px;
			position: absolute;
			top: 0px;
		}

		.label1 {
			left: 0px;
		}

		.label2 {
			left: 50%;
			margin-left: -66px;
		}

		.label3 {
			right: 0px;
		}

		.bar {
			position: absolute;
			height: 10px;
			background-color: var(--color-secondary);
			top: 40px;
		}

		.bar1 {
			width: 45%;
			left: 40px;
		}

		.bar2 {
			width: 50%;
			right: 51px;
		}

		.point {
			height: 30px;
			width: 30px;
			border-radius: 50%;
			background-color: var(--color-secondary);
			line-height: 30px;
			font-size: 24px;
			position: absolute;
			color: var(--color-white);
			top: 30px;
			text-align: center;

		}

		.point1 {
			left: 13px;
		}

		.point2 {
			left: 50%;
			margin-left: -15px;
		}

		.point3 {
			right: 22px;
		}

		.transparent {
			opacity: 0.5;
		}

	}


}

.bank-detail-box {
	padding-bottom: 30px;
}

.amount-options-list {
	margin: 20px 0;

	.amount-option-btn {
		.amount-or {
			text-align: center;
			margin: 15px 0;
			width: 100%;
			max-width: 305px;
		}

		.btn-default {
			width: 100%;
			max-width: 305px;
		}
	}



}