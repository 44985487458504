.facility-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.region-name {
		margin-top: -20px;
		color: var(--color-grey-dark);
	}

	.facility-map {
		width: 100%;
		height: 270px;
		position: relative;

		@media (min-width:1500px) {
			height: 300px;
		}
	}

	h1 {
		color: var(--color-primary);
		font-size: 24px;

	}

	.heading1 {
		text-transform: none;
	}

	.addr {
		background-color: var(--color-grey-light);
		padding: 10px;
		margin-bottom: 20px;

		@media (max-width:1200px) {
			margin-top: 20px;
		}
	}

	.no-picture {
		overflow: hidden;
		width: 100%;
		height: 387px;

		.cover-img {
			width: 100%;
			height: 387px;
		}
	}

	.owl-carousel {
		overflow: hidden;
		width: 100%;
		height: 387px;



		.owl-nav {
			button {
				width: 35px;
				height: 35px;
				top: 160px;
				position: absolute;
				border-radius: 50%;
				cursor: pointer;
				visibility: hidden;
				opacity: 0;
				transition: visibility 0s, opacity 0.5s linear;
				color: var(--color-black);
				font-size: 40px;
				line-height: 1px;
				text-align: center;
				background-color: rgba(255, 255, 255, 0.7);
			}

			.owl-prev {
				left: 10px;

				span {
					display: inline-block;
					padding-bottom: 7px;
					padding-right: 1px;
				}
			}

			.owl-next {
				right: 10px;

				span {
					display: inline-block;
					padding-bottom: 7px;
					padding-left: 1px;
				}
			}
		}
	}

	.owl-carousel {

		&:hover {
			.owl-nav {
				button {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}

}