.lage-anfahrt {

	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-villa-luna.jpg);
	}

	.anreise-map {
		width: 100%;
		height: 415px;
		margin-bottom: 30px;
	}
}