.mountainbike {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-bike3.jpg);
	}

	ol {
		ol {
			margin-top: 20px;
		}
	}

	.unit-list-section {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 20px;

		@media (min-width:1500px) {
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: 20px;
		}

		@media (max-width:1499px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 20px;
		}

		@media (max-width:767px) {
			grid-template-columns: 1fr;
		}

	}

	.button-col {}

	.maps {
		display: table;
		margin-bottom: 20px;
		background-color: var(--color-grey-light);
		width: 100%;
		padding: 10px;

		.image {
			display: table-cell;
			padding-right: 30px;
			width: 140px;
		}

		.text {
			display: table-cell;
			vertical-align: center;
		}
	}


}