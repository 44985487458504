@font-face {
	font-family: 'Didact Gothic';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/didact-gothic-v14-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/didact-gothic-v14-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Rozha One';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/rozha-one-v13-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/rozha-one-v13-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'dexteratrial';
	src: url('RESOURCE/fonts/dextera-webfont.woff2') format('woff2'),
		url('RESOURCE/fonts/dextera-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}