.hotel {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-rosenhof.jpg);
	}

	.img-full {
		img {
			width: 100%;
			height: auto;
			padding-bottom: 20px;
		}
	}

	.content-block {

		ul {
			list-style-type: disc;
			margin-left: 5px;

			li {
				margin-left: 15px;
			}

			ul {
				margin-bottom: 15px;
			}

		}

		.icon-set {
			margin-bottom: 30px;
		}

	}

	.center-block {
		font-weight: bold;
		text-align: center;
	}
}