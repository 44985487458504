.stellenangebote {

	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-brocken.jpg);
	}

	.content-block {

		img {
			width: 100%;
			height: auto;
			padding-bottom: 15px;
		}

		ul {
			list-style-type: disc;
			margin-left: 5px;

			li {
				margin-left: 15px;
			}

		}

		.spacer {
			height: 1px;
			margin: 15px 0;
			border-bottom: 1px solid #000;

		}


	}



}