.facility-card-v1 {
	.content {
		padding: 10px;
		display: flex;
		flex-direction: column;
		min-height: 190px;
		justify-content: space-between;
		border: 1px solid var(--color-grey-normal);

		background-color: var(--color-white);



		.fa {
			padding-right: 5px;
		}

		.fa-map-marker-alt {
			padding-left: -10px;
		}

		.label-container {
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;

			.label {
				padding: 5px 10px;
				color: white;
				border-radius: 5px;
				font-weight: 500;
				width: fit-content;
				font-weight: 500;
				line-height: 25px;
				font-size: var(--font-size-md);
			}

			.btn {
				font-size: var(--font-size-md);
			}

			.customer-rating {
				background: var(--color-secondary);
				font-weight: 500;
			}
		}


	}

	.h5 {
		font-size: 1.1rem;
		color: var(--color-primary);
		margin-bottom: 10px;
	}

	.description {
		font-size: var(--font-size-md);

		div {
			width: 100%;
			height: 101px;
			line-height: 20px;
			/*word-break: break-all;*/
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-moz-box-orient: vertical;
			-ms-box-orient: vertical;
			box-orient: vertical;
			-webkit-line-clamp: 4;
			-moz-line-clamp: 4;
			-ms-line-clamp: 4;
			line-clamp: 4;
			overflow: hidden;
			margin-bottom: 10px;
		}
	}


	.comodity {
		padding: 5px;
	}

	.image-wrap {
		position: relative;

		&:hover {
			img {
				filter: brightness(0.7);
			}
		}

		img {
			width: 100%;
			transition: 0.5s;

		}




	}

}