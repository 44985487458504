.kontaktformular {

	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-angebot.jpg);
	}

	label {
		display: none;
	}

}