.corona-layer {

	width: 500px;
	height: 500px;
	pointer-events: none;
	position: fixed;
	bottom: 30px;
	left: 50%;
	margin-left: -250px;
	z-index: 200;

	@media (max-width:992px) {
		bottom: 20px;
	}

	@media (max-width:560px) {
		width: 350px;
		height: 500px;
		margin-left: -175px;
		bottom: 10px;
	}

	/*border: 2px solid #000;*/

	.trigger {
		position: absolute;
		bottom: 0px;
		width: 100%;

		.hint {
			font-size: 22px;
			padding: 15px;
			cursor: pointer;
			pointer-events: all;
			background: var(--color-primary);
			color: #fff;
			max-width: 350px;
			border: 1px solid var(--color-primary);
			margin: 0 auto;
			text-align: center;

			&:hover {
				background: #fff;
				color: #000;
			}


		}


		.info {
			position: absolute;
			right: 0px;
			bottom: 0px;
			background: var(--color-primary);
			display: none;
			padding: 15px;
			text-align: center;
			pointer-events: all;
			cursor: pointer;
			color: #fff;
			border: 1px solid var(--color-primary);

			.fa {
				font-size: 28px;

			}

			&:hover {
				background: white;
				color: var(--color-primary);
			}

		}
	}

	.info-layer {
		pointer-events: all;
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 20px;
		display: none;



		.info-text {
			position: absolute;
			bottom: 0px;
			background: #fff;
			font-size: 16px;
			padding: 15px;
			border: 1px solid #000;

			.info-close {
				position: absolute;
				right: -10px;
				top: -15px;
				color: #000;
				font-size: 20px;
				background-color: #fff;
				width: 30px;
				height: 30px;
				cursor: pointer;
				text-align: center;
				border-radius: 100%
			}
		}
	}



	&.small {
		right: 0px;
		bottom: 100px;
		top: unset;
		left: unset;

		@media (max-width:560px) {
			bottom: 80px;
		}



		.hint {
			display: none;

		}

		.info {
			display: block;
		}

		&.open {
			right: 10px;
		}
	}

	&.open {
		.info-layer {
			display: block;
		}

		.info {
			display: none;
		}

		.hint {
			-webkit-transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			transition: all 0.5s ease;
			display: none;
		}
	}


}