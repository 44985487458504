.link-bar {
	background-color: var(--color-secondary);
	color: var(--color-white);
	padding: 20px 0px;

	.fal {
		width: 40px;
		height: 40px;
		left: 0;
		top: 3px;
		position: absolute;
		text-align: center;
		border: 1px solid #fff;
		border-radius: 100%;
		font-size: 26px;
		line-height: 36px;
	}

	.check {
		padding-left: 35px;
	}

	.unit-search {
		.btn {
			padding: 0px 15px;
		}
	}

}