.sidebar-nav {
	background-color: var(--color-white);
	padding: 15px;
	margin-bottom: 20px;

	ul {
		margin: 0px;
	}

	a {
		display: block;
		line-height: 36px;
		color: var(--color-main-font);
		background-color: var(--color-grey-light);
		text-indent: 10px;
		margin-bottom: 1px;


		&:before {
			margin-right: 12px;
			font: 12px FontAwesome;
			content: "\f105";
			color: var(--color-secondary);

		}


		&:hover {
			color: var(--color-white);
			background-color: var(--color-secondary);
			text-decoration: none;

			&:before {
				margin-right: 12px;
				font: 12px FontAwesome;
				content: "\f105";
				color: var(--color-white);
			}
		}

		&.active {
			color: var(--color-white);
			background-color: var(--color-secondary);

			&:before {
				margin-right: 12px;
				font: 12px FontAwesome;
				content: "\f105";
				color: var(--color-white);
			}
		}




	}

}