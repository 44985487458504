.unit-description-v2 {

	.title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		margin-bottom: 0rem;
	}

	.description {

		&.next {
			margin-top: 60px;
		}
	}

}