.offer-blog {

	padding-top: var(--page-margin-top);

	.fewo_offer {
		box-shadow: 0 2px 1px rgb(0 0 0 / 10%);
		margin-bottom: 20px;
		background-color: var(--color-white);

		.offer-img {
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.offer-text {
			min-height: 200px;

			@media(max-width:767px) {
				padding-left: 30px;
			}
		}

		.offer-title {
			display: inline-block;
			margin-top: 10px;
			margin-bottom: 10 px;
			font-size: 20px;
			line-height: 1.2;
			color: #f55856;

			a {
				text-decoration: none;
				color: #bb0067;

			}

			a:hover {
				text-decoration: underline;
			}

		}

		.offer_time {
			left: 15px;
			bottom: 15px;
			position: absolute;
			display: inline-block;
			padding: 4px 14px;
			border: 1px solid #E00019;
			font-weight: bold;
			color: #E00019;

			@media(max-width:767px) {
				left: 30px;
			}

			@media(max-width:440px) {
				left: 30px;
				bottom: inherit;
			}
		}

		.offer_link_details {
			height: 40px;
			bottom: 15px;
			right: 30px;
			position: absolute;
			overflow: visible;
			display: inline-block;
			margin: 0;
			padding: 0 40px 0 20px;
			white-space: nowrap;
			background: #bb0067 url(RESOURCE/img/arrow-right-white.svg) right center no-repeat;
			-webkit-box-shadow: 0 0 3px #333;
			box-shadow: 0 0 3px #333;
			border: 2px solid #ffffff;
			border-radius: 3 px;
			font-size: 14px;
			line-height: 36px;
			font-weight: bold;
			color: #ffffff;
			zoom: 1;
			cursor: pointer;
			background-size: 30px 30px;
			background-position: right 5px center;

			&:hover {
				background-color: #afcb08;
				text-decoration: none;
			}

		}

	}
}