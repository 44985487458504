.unit-navbar-v1 {
	background-color: var(--unit-navbar-bg-color);
	z-index: 14;
	width: 100%;

	&.fixed {
		position: fixed;
		top: 0px;
	}




	.fl-menu-itm {
		padding: 20px 10px;
		color: var(--unit-navbar-font-color-item);
		display: inline-block;
		text-transform: uppercase;
		font-size: var(--unit-navbar-font-size-item);

		@media (max-width:1500px) {
			padding: 20px 6px;
		}

		@media (max-width:1200px) {
			padding: 20px 6px;
		}

		&:hover {
			color: var(--unit-navbar-font-color-item-hover);
			background-color: var(--unit-navbar-bg-color-item-hover);
			text-decoration: none;
		}

		&.active {
			color: var(--unit-navbar-font-color-item-hover);
			background-color: var(--unit-navbar-bg-color-item-hover);
			text-decoration: none;
		}

		@media (max-width:1200px) {
			/*font-size: var(--font-size-sm);*/
			font-size: 13px;
		}

	}


	@media (max-width:992px) {
		display: none;
	}
}