.impressum {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-wandern.jpg);
	}

	ol {
		ol {
			margin-top: 20px;
		}
	}

}