.direkt-reservieren {
	background-color: var(--color-white);
	padding: 15px;

	.title {
		font-size: 18px;
		color: var(--title-font-color-main);
		padding-bottom: 15px;
	}

	.image {
		padding-bottom: 15px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}