.eigentuemer {

	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-eigentuemer.jpg);
	}

	.img-pakete {
		img {
			width: 100%;
			height: auto;
			padding-bottom: 20px;
		}
	}

	.img-tabelle {
		img {

			@media (max-width:1183px) {
				width: 100%;
				height: auto;
			}

			padding-bottom: 20px;
		}
	}

	.content-block {

		ul {
			list-style-type: disc;
			margin-left: 15px;

			li {
				margin-left: 15px;
			}

			ul {
				margin-bottom: 15px;
			}

		}

	}

	.center-block {
		font-weight: bold;
		text-align: center;
	}

}